/* List Item Checkmark */
ul li::before {
  content: "✓";
  display: inline-block;
  margin-right: 10px; /* Adjust the value as needed */
  color: #FFA500;
  font-weight: bold;
}

/* Logo Styling */
.App-logo {
  height: 30px;
  pointer-events: none;
}

/* Header Styling */
.App-header {
  background-color: #282c34;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: white;
  position: relative;
  min-height: 60px;
}

/* Menu Toggle Button */
.menu-toggle {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 2;
}

/* Navigation Styling */
.App-header nav {
  transition: all 0.3s ease-in-out;
}

.App-header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.App-header nav ul li {
  display: inline;
}

.App-header nav ul li a {
  color: white;
  text-decoration: none;
  padding: 10px;
}

.App-header nav ul li a:hover {
  text-decoration: underline;
  background-color: #ffa500;
  color: #282c34;
  border-radius: 5px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .App-header nav {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #282c34;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 3;
  }

  .App-header.nav-open nav {
    display: block;
  }

  .App-header nav ul {
    flex-direction: column;
    width: 100%;
  }

  .App-header nav ul li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
}
