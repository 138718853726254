/* Existing styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global styles for h2 and p */
.h2-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.orange-mark {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #FF4500; /* Accent color for the vertical line */
  margin-right: 10px; /* Space between the line and the h2 text */
}

h2 {
  font-size: 2.5em; /* Consistent font size for all h2 tags */
  font-weight: bold;
  margin: 0; /* Remove margin */
  padding-left: 15px; /* Add padding to create space for the orange mark */
}

p {
  font-size: 1.2em; /* Consistent font size for all p sections */
  margin-bottom: 20px;
  line-height: 1.6;
  font-family: 'Montserrat', sans-serif; /* Ensure consistent font style */
}

/* Styling for lists within paragraphs */
p ul {
  list-style-type: none;
  padding-left: 0;
}

p ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1.25em; /* Slightly different font size for list items */
  font-weight: 500; /* Different font weight for emphasis */
}

/* Styling for links within paragraphs */
p a {
  color: #1E90FF; /* Link color */
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}

/* Layout adjustments for mobile view */
@media (max-width: 768px) {
  .Section {
    padding: 20px 15px;
  }

  .Section-content {
    flex-direction: column;
  }

  .Section-column {
    padding: 10px 0;
  }

  /* Ensure consistent font size on mobile */
  h2, p {
    font-size: inherit; /* Ensure inheritance of global font sizes */
  }

  /* Explicitly set mobile font sizes */
  h2 {
    font-size: 1.8em; /* Adjust as needed for mobile */
  }

  p {
    font-size: 1em; /* Adjust as needed for mobile */
    font-family: 'Montserrat', sans-serif; /* Ensure consistent font style */
  }
}

.Mission-section p,
.Vision-section p,
.Values-section p,
.Products-section p,
.AboutUs-section p {
  font-size: 1.5em; /* Consistent font size for all p sections */
  margin-bottom: 20px;
  line-height: 1.6;
  font-family: 'Montserrat', sans-serif; /* Ensure consistent font style */
}

/* Section Styling */
.Section {
  padding: 50px 20px;
  text-align: center;
  max-width: 1200px; /* Set a maximum width for the content */
  margin: 0 auto; /* Center the content horizontally */
}

.Section-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  /* Align items to the top */
}

.Section-column {
  flex: 1;
  min-width: 300px;
  padding: 20px;
}

.Section-column.text {
  text-align: left;
}

.Section-column.image {
  text-align: center;
}

.Section-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.separator {
  height: 2px;
  background-color: #ccc;
  margin: 20px 0;
}

/* Additional mobile styles for small screens */
@media (max-width: 480px) {
  .Section-column.text {
    margin-top: 10px;
  }

  .Section-image {
    margin-bottom: 10px;
  }

  h2, p {
    font-size: 1.2em;
  }

  .orange-mark {
    width: 3px;
    margin-right: 5px;
  }

  .Mission-section p,
  .Vision-section p,
  .Values-section p,
  .Products-section p,
  .AboutUs-section p {
    font-size: 1em; /* Consistent font size for all p sections */
    margin-bottom: 20px;
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif; /* Ensure consistent font style */
  }
}

/* Additional media query for landscape iPad screens and larger */
@media (min-width: 1024px) {
  .Section-content {
    flex-direction: row;
    justify-content: space-between; /* Space out the columns more evenly */
    max-width: 80%; /* Set a maximum width */
    margin-left: auto; /* Center the section */
    margin-right: auto; /* Center the section */
  }
}
