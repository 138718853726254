/* Existing styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global styles for h2 and p */
.h2-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.orange-mark {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #FF4500; /* Accent color for the vertical line */
  margin-right: 10px; /* Space between the line and the h2 text */
}

h2 {
  font-size: 2.5em; /* Consistent font size for all h2 tags */
  font-weight: bold;
  margin: 0; /* Remove margin */
  padding-left: 15px; /* Add padding to create space for the orange mark */
}

p {
  font-size: 1.2em; /* Consistent font size for all p sections */
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Styling for lists within paragraphs */
p ul {
  list-style-type: none;
  padding-left: 0;
}

p ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1.25em; /* Slightly different font size for list items */
  font-weight: 500; /* Different font weight for emphasis */
}

/* Styling for links within paragraphs */
p a {
  color: #1E90FF; /* Link color */
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}

/* Layout adjustments for mobile view */
@media (max-width: 768px) {
  .Section {
    padding: 20px 15px;
  }

  .Section-content {
    flex-direction: column;
  }

  .Section-column {
    padding: 10px 0;
  }

  /* Ensure consistent font size on mobile */
  h2, p {
    font-size: inherit; /* Ensure inheritance of global font sizes */
  }

  /* Explicitly set mobile font sizes */
  h2 {
    font-size: 1.8em; /* Adjust as needed for mobile */
  }

  p {
    font-size: 1em; /* Adjust as needed for mobile */
  }
  
  /* Expertise section specific styles */
  .Expertise-section h2 {
    font-size: 1.8em; /* Ensure this matches other sections */
  }

  .Expertise-section p {
    font-size: 1em; /* Ensure consistent font size for paragraphs in Expertise section */
  }
}

/* Layout adjustments for larger screens */
@media (min-width: 769px) {
  .Section-content {
    display: flex;
    align-items: flex-start;
    /* Align items to the top */
  }

  .Section-column.text {
    flex: 2;
    margin-right: 20px;
  }

  .Section-column.image {
    flex: 1;
    text-align: right;
  }

  .Section-image-wrapper {
    max-width: 100%;
  }

  .Section-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

/* Hero Section */
.Home-hero {
  position: relative;
  text-align: center;
  color: white;
  padding: 100px 20px;
  overflow: hidden;
}

.Home-hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
  z-index: 1;
}

.Home-heading {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3em;
  font-weight: 700;
  color: #FFFFFF;
  z-index: 2;
}

.Hero-content {
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  animation: fadeIn 1s ease-in-out;
  margin-top: 70px;
}

.Home-hero h1,
.Home-hero h3,
.Home-hero p {
  margin: 0;
  padding: 10px 0;
  color: #FFFFFF;
}

.Home-hero h3 {
  font-size: 2em;
  font-weight: 600;
  color: #FFA500;
}

.Typewriter-container {
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Typewriter {
  display: inline;
}

.Home-hero p {
  font-size: 1.2em;
  font-weight: 300;
  margin-bottom: 20px;
}

/* CTA Section */
.CTA-section {
  background-size: cover;
  background-position: center;
  color: white;
}

.CTASection-content {
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  animation: fadeIn 1s ease-in-out;
  margin-top: 70px;
}

.CTA-section h3 {
  font-size: 1.5em;
}

.cta-button {
  background-color: #FFA500;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #1E90FF;
  color: white;
}

/* Section Styling */
.Section {
  padding: 50px 20px;
  text-align: center;
}

.Section-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  /* Align items to the top */
}

.Section-column {
  flex: 1;
  min-width: 300px;
  padding: 20px;
}

.Section-column.text {
  text-align: left;
}

.Section-column.image {
  text-align: center;
}

.Section-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.separator {
  height: 2px;
  background-color: #ccc;
  margin: 20px 0;
}

ul {
  padding-left: 20px;
  list-style-type: none;
}

ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1.25em; /* Slightly different font size for list items */
  font-weight: 500; /* Different font weight for emphasis */
}


/* Mobile Styles */
@media (max-width: 768px) {
  .Section {
    padding: 20px 15px;
  }

  .Section-content {
    flex-direction: column;
  }

  .Section-column {
    padding: 10px 0;
    min-width: unset;
  }

  .Section-column.image {
    margin-bottom: 10px;
  }

  .Section-column.text {
    margin-top: 10px;
  }

  .Section-image {
    margin-bottom: 10px;
  }

  .separator {
    margin: 20px 0;
  }

  .Section+.Section {
    margin-top: 30px;
  }

  /* Ensure consistent font size on mobile */
  h2, p {
    font-size: 1.8em; /* Adjust as needed for mobile */
    font-size: 1em; /* Adjust as needed for mobile */
  }
}

/* Desktop Styles */
@media (min-width: 769px) {
  .Section-content {
    flex-direction: row;
  }

  .alternate .Section-column.text {
    order: 1;
  }

  .alternate .Section-column.image {
    order: 2;
  }

  .reverse .Section-column.text {
    order: 2;
  }

  .reverse .Section-column.image {
    order: 1;
  }

  .Expertise-section .Section-content,
  .About-section .Section-content {
    display: flex;
    align-items: flex-start;
    /* Align items to the top */
  }

  .Expertise-section .Section-image,
  .About-section .Section-image {
    width: 40%;
    border-radius: 10px;
    margin-top: 0;
    /* Remove top margin */
  }

  .Expertise-section img,
  .About-section img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .Expertise-section .Section-content {
    padding-right: 40px;
    /* Add right padding */
  }

  .Expertise-section .Section-image {
    float: right;
    margin: 0 0 20px 20px;
  }

  .About-section .Section-content,
  .Product-section .Section-content,
  .Expertise-section .Section-content {
    padding-left: 40px;
  }

  .About-section .Section-column.text,
  .Product-section .Section-column.text,
  .Expertise-section .Section-column.text {
    text-align: left;
  }

  .About-section .Section-image-wrapper,
  .Product-section .Section-image-wrapper {
    float: left;
    width: 40%;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .Expertise-section .Section-image-wrapper {
    float: right;
    width: 40%;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .About-section .Section-image,
  .Product-section .Section-image,
  .Expertise-section .Section-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .About-section h2,
  .Product-section h2,
  .Expertise-section h2 {
    margin-top: 0;
  }

  /* Clear the float after the section content */
  .About-section .Section-content::after,
  .Product-section .Section-content::after,
  .Expertise-section .Section-content::after {
    content: "";
    display: table;
    clear: both;
  }

  .Section-column.image,
  .Section-column.text {
    align-self: flex-start;
    /* Align items to the top */
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .Home-hero h1 {
    font-size: 1.5em;
    line-height: 1.2;
  }

  .Home-hero h3 {
    font-size: 1.2em;
  }

  .Home-heading {
    font-size: 2em;
    top: 20px;
  }

  .Hero-content {
    margin-top: 40px;
  }

  .Home-hero p {
    font-size: 0.9em;
  }

  .cta-button {
    font-size: 0.9em;
  }

  .About-section h2,
  .Vision-section h2,
  .Team-section h2,
  .Product-section h2,
  .Benefits-section h2,
  .CTA-section h2 {
    font-size: 1.8em;
  }

  .About-section p,
  .Vision-section p,
  .Team-section p,
  .Product-section p,
  .Benefits-section p,
  .CTA-section p {
    font-size: 1em;
  }
}
